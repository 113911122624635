import React from "react";
import "./home.css";
import "../about/about.css";
import "../features/features.css";
import aboutImg from "../../assets/description.png";
import coffe_cb from "../../assets/coffe_cb.png";
import image1 from "../../assets/image1.png";
import image2 from "../../assets/image2.png";
import image3 from "../../assets/image3.png";
import image4 from "../../assets/iamge4.png";
import image_1 from "../../assets/image_1.png";
import image_2 from "../../assets/image_2.png";
import image_3 from "../../assets/image_3.png";
import image_4 from "../../assets/image_4.png";
import image_5 from "../../assets/image_5.png";
import image_6 from "../../assets/image_6.png";
import image_7 from "../../assets/image_7.png";
import image_8 from "../../assets/image_8.png";
import partner from "../../assets/partner.png";
import ocop from "../../assets/ocop.png";
import certificate from "../../assets/certificate.png";
import { features, features1 } from "../../Data";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import { Link } from "react-router-dom";
import { Button } from "antd";
// Thêm module navigation
SwiperCore.use([Autoplay, Navigation]);
// Import Swiper styles
// import "swiper/css";

const Product = () => {
  const handleBookNow = () => {
    window.open("https://m.me/317168162494049", "_blank");
  };
  return (
    <div style={{ backgroundColor: "white", marginBottom: "100px" }}>
      <section
        className="about section"
        id="about"
        style={{ marginTop: "0px" }}
      >
        <div
          className="features__grid container"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="about__img-wrapper-test"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "stretch", // Đảm bảo các div con có chiều cao bằng nhau
              gap: "60px", // Tạo khoảng cách giữa các div (nếu cần)
            }}
          >
            <div
              style={{
                textAlign: "center", // Căn giữa nội dung theo chiều ngang
                backgroundColor: "#864622", // Màu nền giống ảnh
                padding: "20px", // Tạo khoảng cách bên trong
                borderRadius: "8px", // Bo góc
                color: "white", // Màu chữ trắng
                fontSize: "16px", // Kích thước chữ
                lineHeight: "1.5", // Khoảng cách giữa các dòng
              }}
            >
              <div style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Danh sách
              </div>
              <hr style={{ fontWeight: "bold", marginBottom: "10px" }} />
              <div>Bánh Tam Giác Mạch</div>
              <div>Trà San Tuyết</div>
            </div>

            <div
              style={{
                textAlign: "center",
                flex: 1, // Đảm bảo các div con có thể mở rộng đều
                // display: "flex", // Để căn giữa nội dung bên trong
                // justifyContent: "center",
                // alignItems: "center",
              }}
            >
              <img src={image_7} alt="" className="about__image-test-1" />
              <p
                className="about__description"
                style={{
                  color: "#864622",
                  textAlign: "center",
                  marginTop: "10px",
                }}
              >
                Bánh Tam Giác Mạch
              </p>
              <p
                className="about__description"
                style={{
                  color: "#864622",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                100.000 VNĐ
              </p>
              <Button
                type="primary"
                size="large"
                onClick={handleBookNow}
                className="bg-[#864622] font-bold text-white"
                style={{ borderRadius: "25px" }}
              >
                ĐẶT HÀNG NGAY
              </Button>
            </div>
            <div
              style={{
                textAlign: "center",
                flex: 1, // Đảm bảo các div con có thể mở rộng đều
                // display: "flex", // Để căn giữa nội dung bên trong
                // justifyContent: "center",
                // alignItems: "center",
              }}
              className=""
            >
              <img src={image_8} alt="" className="about__image-test-1" />
              <p
                className="about__description"
                style={{
                  color: "#864622",
                  textAlign: "center",
                  marginTop: "10px",
                }}
              >
                Trà San Tuyết - Shan Tea
              </p>
              <p
                className="about__description"
                style={{
                  color: "#864622",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                55.000 VNĐ
              </p>
              <Button
                type="primary"
                size="large"
                onClick={handleBookNow}
                className="bg-[#864622] font-bold text-white"
                style={{ borderRadius: "25px" }}
              >
                ĐẶT HÀNG NGAY
              </Button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Product;
