import React from "react";
import "./home.css";
import "../about/about.css";
import "../features/features.css";
import aboutImg from "../../assets/description.png";
import coffe_cb from "../../assets/coffe_cb.png";
import image1 from "../../assets/image1.png";
import image2 from "../../assets/image2.png";
import image3 from "../../assets/image3.png";
import image4 from "../../assets/iamge4.png";
import image_1 from "../../assets/image_1.png";
import image_2 from "../../assets/image_2.png";
import image_3 from "../../assets/image_3.png";
import image_4 from "../../assets/image_4.png";
import image_5 from "../../assets/image_5.png";
import image_6 from "../../assets/image_6.png";
import image_7 from "../../assets/image_7.png";
import image_8 from "../../assets/image_8.png";
import partner from "../../assets/partner.png";
import ocop from "../../assets/ocop.png";
import certificate from "../../assets/certificate.png";
import { features, features1 } from "../../Data";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import { Link } from "react-router-dom";
import { Button } from "antd";
// Thêm module navigation
SwiperCore.use([Autoplay, Navigation]);
// Import Swiper styles
// import "swiper/css";

const Home = () => {
  const handleBookNow = () => {
    window.open("https://m.me/317168162494049", "_blank");
  };
  return (
    <div style={{ backgroundColor: "white" }}>
      <section className="home" id="home">
        <div className="home__container container">
          <div className="home__content">
            {/* <h1
                            style={{
                                fontFamily: 'Montserrat',
                                fontWeight: '900',
                                fontSize: '60px',
                                lineHeight: '78.02px',
                                textAlign: 'left'
                            }}
                        >
                            CAFE CỰC BẮC
                        </h1> */}
            <div className="">
              <img src={coffe_cb} alt="" className="about__image" />
            </div>
            <p
              className="home__description"
              style={{
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "bold",
                textAlign: "left",
                marginTop: "18px",
              }}
            >
              Tiệm cafe mang giai điệu đồng bào dân tộc Lô Lô - Nơi gìn giữ,
              phát huy giá trị tinh hoa văn hoá Lô Lô Chải.
            </p>
            <div className="flex mt-[40px]">
              <Button
                type="primary"
                size="large"
                className="bg-white font-bold text-[#864622]"
                style={{ borderRadius: "25px" }}
              >
                KHÁM PHÁ NGAY
              </Button>
            </div>
            {/* <div className="home__btns">
                        <a href="#menu" className="btn">Check Menu</a>
                        <a href="#reservation" className="btn home__btn">Book Table</a>
                    </div> */}
          </div>
        </div>
      </section>
      <section className="about section" id="about">
        <h2
          className="section__title title-left"
          style={{
            fontFamily: "Montserrat",
            fontSize: "24px",
            fontWeight: "700",
            lineHeight: "29.26px",
            textAlign: "center",
            color: "#864622",
            marginTop: "-90px",
            marginBottom: "20px",
          }}
        >
          SỰ RA ĐỜI CỦA CAFE CỰC BẮC
        </h2>
        <div className="about__grid container grid">
          <div className="ml-0 md:ml-3" style={{ textAlign: "center" }}>
            <img src={aboutImg} alt="" className="about__image" />
            <p
              className="about__description"
              style={{
                color: "#864622",
                fontStyle: "italic",
                textAlign: "center",
                marginTop: "10px",
              }}
            >
              Bác Ogura tại quán Cafe Cực Bắc
            </p>
          </div>

          <div className="about__content" style={{ marginTop: "-30px" }}>
            <p
              className="about__description"
              style={{
                color: "#864622",
                textAlign: "justify",
                fontWeight: "bold",
              }}
            >
              Bác Yasushi Ogura (người Nhật Bản, 67 tuổi) đến thăm Việt Nam vào
              năm 1995 và trót "phải lòng" với mảnh đất Hà Giang. Từ trước khi
              du lịch phát triển, bác đã đặc biệt quan tâm đến văn hoá đồng bào
              Lô Lô Chải như: kiến trúc nhà ở, nét văn hoá truyền thống...
            </p>
            <p
              className="about__description"
              style={{
                marginTop: "10px",
                color: "#864622",
                textAlign: "justify",
                fontWeight: "bold",
              }}
            >
              Vì vậy, bác quyết định giúp gia đình chúng tôi mở quán cà phê ngay
              trên chính ngôi nhà trình tường đã 200 năm tuổi. Đây là quán cafe
              đầu tiên ở Lô Lô Chải với mong muốn bảo tồn nhà trình tường đất và
              nét văn hoá của người Lô Lô. Bác nói rằng: "người Lô Lô trực tiếp
              kinh doanh và đưa nét văn hoá của dân tộc mình đến với du khách
              mới thực sự ý nghĩa."
            </p>
            <p
              className="about__description"
              style={{
                marginTop: "10px",
                color: "#864622",
                textAlign: "justify",
                fontWeight: "bold",
              }}
            >
              Ngoài quán cafe Cực Bắc, bác còn giúp đỡ và góp ý cho thôn Lô Lô
              Chải phát triển về du lịch, bảo tồn văn hoá.
            </p>
          </div>
        </div>
        <div className="about__grid container" style={{ marginTop: "64px" }}>
          {/* <div className="about__img-wrapper-test" style={{ display: 'flex', justifyContent: 'center', gap: '85px' }}>
                        <div style={{ textAlign: 'center' }}>
                            <img src={image1} alt="" className="about__image-test-1" />
                            <p className="about__description" style={{ color: '#864622', fontStyle: 'italic', textAlign: 'center', marginTop: '10px' }}>
                                Nụ cười mến khách của chị chủ nhà Lù Thị Vấn
                            </p>
                        </div>
                        <div style={{ textAlign: 'center', marginTop: '-10px' }}>
                            <img src={image2} alt="" className="about__image-test-1" />
                            <p className="about__description" style={{ color: '#864622', fontStyle: 'italic', textAlign: 'center', marginTop: '10px' }}>
                                Hoa đào nở rộ tại Cafe Cực Bắc vào mỗi dịp Tết đến xuân về.
                            </p>
                        </div>
                    </div> */}
          <div
            className="about__img-wrapper-test"
            style={{ display: "flex", justifyContent: "center", gap: "35px" }}
          >
            {/* <img src={image1} alt="" className="about__image-test-1" />
                        <img src={image2} alt="" className="about__image-test-1" /> */}
            <div style={{ textAlign: "center" }}>
              <img src={image1} alt="" className="about__image-test-1" />
              <p
                className="about__description"
                style={{
                  color: "#864622",
                  fontStyle: "italic",
                  textAlign: "center",
                  marginTop: "10px",
                  width: "300px",
                }}
              >
                Nụ cười mến khách của chị chủ nhà Lù Thị Vấn
              </p>
            </div>
            <div
              style={{ textAlign: "center", marginTop: "-30px" }}
              className="ml-0 md:ml-[50px]"
            >
              <img src={image2} alt="" className="about__image-test-1" />
              <p
                className="about__description"
                style={{
                  color: "#864622",
                  fontStyle: "italic",
                  textAlign: "center",
                  marginTop: "10px",
                }}
              >
                Hoa đào nở rộ tại Cafe Cực Bắc vào mỗi dịp Tết đến xuân về.
              </p>
            </div>
          </div>
          <div
            className="-mt-4 md:-mt-0"
            style={{ textAlign: "center", fontWeight: "bold" }}
          >
            <p
              className="mx-0 md:mx-20 "
              style={{ color: "#864622", marginTop: "px" }}
            >
              Cafe Cực Bắc là 1 ngôi nhà cổ niên đại trên 200 tuổi, kiến trúc
              ngôi nhà đặc trưng với nhà trình tường, mái ngói âm dương, bao
              quanh nhà là hàng rào đá với những cây đào cổ thụ bung nở khoe sắc
              mỗi độ Xuân về.{" "}
            </p>
            <p
              className="mx-0 md:mx-20 "
              style={{ color: "#864622", marginTop: "23px" }}
            >
              Hàng năm cứ mùa xuân đến, làng Lô Lô Chải tràn ngập trong vẻ đẹp
              của hoa đào, hoa mận. Cafe Cực Bắc trở thành địa điểm dừng chân
              không thể thiếu của du khách khi đến thăm địa đầu Tổ quốc.
            </p>
            <p
              className="mx-0 md:mx-24"
              style={{ marginTop: "10px", color: "#864622" }}
            >
              Nhâm nhi ly cà phê nóng, trò chuyện cùng người dân bản địa và trải
              nghiệm văn hóa địa phương khiến cho du khách có ấn tượng sâu sắc
              về nơi đây.
            </p>
          </div>
        </div>
        <div className="about__grid container" style={{ marginTop: "52px" }}>
          <div
            className="about__img-wrapper-test"
            style={{ display: "flex", justifyContent: "center", gap: "90px" }}
          >
            {/* <img src={image3} alt="" className="about__image-test-1" />
                        <img src={image4} alt="" className="about__image-test-1" /> */}
            <div style={{ textAlign: "center" }}>
              <img src={image3} alt="" className="about__image-test-1" />
              <p
                className="about__description"
                style={{
                  color: "#864622",
                  fontStyle: "italic",
                  textAlign: "center",
                  marginTop: "10px",
                }}
              >
                Chương trình Có hẹn với Thanh Xuân được ghi hình tại Cafe Cực
                Bắc
              </p>
            </div>
            <div style={{ textAlign: "center" }}>
              <img src={image4} alt="" className="about__image-test-1" />
              <p
                className="about__description"
                style={{
                  color: "#864622",
                  fontStyle: "italic",
                  textAlign: "center",
                  marginTop: "10px",
                }}
              >
                Phân cảnh trong MV “OK” của Binz
              </p>
            </div>
          </div>
          <div className="about__content" style={{ textAlign: "center" }}>
            <p
              className="mx-0 md:mx-24"
              style={{
                marginTop: "52px",
                color: "#864622",
                fontWeight: "bold",
              }}
            >
              Ngoài ra, Cafe Cực Bắc còn là địa điểm được lựa chọn để quay các
              chương trình thực tế như Có hẹn với Thanh Xuân hay các chương
              trình khác và xuất hiện trong một vài MV ca nhạc như “OK - Binz”.{" "}
            </p>
          </div>
        </div>
        <section className="features section" id="features">
          <h2
            className="section__title title-left"
            style={{
              fontFamily: "Montserrat",
              fontSize: "24px",
              fontWeight: "700",
              lineHeight: "29.26px",
              textAlign: "center",
              color: "#864622",
              marginTop: "-90px",
              marginLeft: "20px",
              marginRight: "20px",
              marginBottom: "48px",
            }}
          >
            HOMESTAY CAFE CỰC BẮC - KHÔNG GIAN MANG ĐẬM BẢN SẮC VĂN HÓA LÔ LÔ
          </h2>
          <div style={{ textAlign: "center", width: "100%" }}>
            <img
              src={image_1}
              alt=""
              className="about__image-test-1"
              style={{ textAlign: "center", width: "100%" }}
            />
          </div>
          <div className="about__content" style={{ textAlign: "center" }}>
            <p
              className="mx-0 md:mx-24"
              style={{
                marginTop: "52px",
                color: "#864622",
                fontWeight: "bold",
              }}
            >
              Giữa miền đá xám Hà Giang, nép mình trong ngôi làng nhỏ Lô Lô
              Chải, Homestay Cafe Cực Bắc hiện lên như một bức tranh cổ kính,
              nơi lưu giữ trọn vẹn những giá trị văn hóa truyền thống của đồng
              bào Lô Lô. Với tuổi đời 200 năm, ngôi nhà vẫn vững chãi trước thời
              gian, là chứng nhân cho biết bao thế hệ đã sinh sống, gắn bó với
              mảnh đất địa đầu Tổ quốc này.{" "}
            </p>
          </div>
          <div
            className="about__img-wrapper-test"
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "35px",
              marginTop: "20px",
            }}
          >
            {/* <img src={image1} alt="" className="about__image-test-1" />
                        <img src={image2} alt="" className="about__image-test-1" /> */}
            <div style={{ textAlign: "center" }}>
              <img src={image_2} alt="" className="about__image-test-1" />
            </div>
            <div
              style={{ textAlign: "center", marginTop: "0px" }}
              className="ml-0 md:ml-[50px]"
            >
              <img src={image_3} alt="" className="about__image-test-1" />
            </div>
            <div
              style={{ textAlign: "center", marginTop: "" }}
              className="ml-0 md:ml-[50px]"
            >
              <img src={image_4} alt="" className="about__image-test-1" />
            </div>
          </div>
          <div className="about__content" style={{ textAlign: "center" }}>
            <p
              className="mx-0 md:mx-24"
              style={{
                marginTop: "52px",
                color: "#864622",
                fontWeight: "bold",
              }}
            >
              Homestay Cafe Cực Bắc được cải tạo từ ngôi nhà cổ của người Lô Lô,
              vẫn giữ nguyên kết cấu tường trình đất dày, mái ngói âm dương phủ
              đầy rêu phong, những ô cửa gỗ nhỏ. Lối kiến trúc mộc mạc này không
              chỉ tạo nên nét đẹp giản dị mà còn giúp không gian bên trong luôn
              ấm áp vào mùa đông và mát mẻ vào mùa hè. Bước vào homestay, du
              khách như được quay ngược thời gian, trở về với những giá trị
              nguyên sơ của cuộc sống. Những bộ bàn ghế gỗ mộc mạc, những chiếc
              đèn mờ treo trên vách, hay những khung cửa sổ nhỏ mở ra khoảng
              trời bình yên đều khiến lòng người dịu lại. Mỗi chi tiết trong
              ngôi nhà đều mang trong mình một câu chuyện, một dấu ấn của người
              dân nơi đây.
            </p>
          </div>
          <div
            className="about__img-wrapper-test"
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "35px",
              marginTop: "20px",
            }}
          >
            {/* <img src={image1} alt="" className="about__image-test-1" />
                        <img src={image2} alt="" className="about__image-test-1" /> */}
            <div style={{ textAlign: "center" }}>
              <img src={image_5} alt="" className="about__image-test-1" />
            </div>
            <div
              style={{ textAlign: "center", marginTop: "0px" }}
              className="ml-0 md:ml-[50px]"
            >
              <img src={image_6} alt="" className="about__image-test-1" />
            </div>
          </div>
          <div className="about__content" style={{ textAlign: "center" }}>
            <p
              className="mx-0 md:mx-24"
              style={{
                marginTop: "52px",
                color: "#864622",
                fontWeight: "bold",
              }}
            >
              Điểm đặc biệt của Homestay Cafe Cực Bắc chính là khoảng sân chung
              rộng rãi. Đây là nơi mà vào mỗi buổi tối, du khách có thể quây
              quần bên nhau, nhâm nhi chén trà nóng, cùng nhau trò chuyện và
              lắng nghe những câu chuyện văn hóa của đồng bào Lô Lô. Những buổi
              tối se lạnh của Hà Giang, được ngồi bên bếp lửa bập bùng, thưởng
              thức chút rượu ngô cay nồng, lắng nghe tiếng hát vang lên trong
              đêm, đó chính là những trải nghiệm khó quên mà không nơi nào có
              được.
            </p>
          </div>
          <div className="about__content" style={{ textAlign: "center" }}>
            <p
              className="mx-0 md:mx-24"
              style={{
                marginTop: "20px",
                color: "#864622",
                fontWeight: "bold",
              }}
            >
              Giữa những bộn bề của cuộc sống hiện đại, Homestay Cafe Cực Bắc là
              điểm dừng chân lý tưởng dành cho những ai muốn tìm về một chốn yên
              bình, tách biệt khỏi những xô bồ thường nhật. Đây không chỉ là nơi
              để nghỉ lại qua đêm mà còn là nơi để cảm nhận sâu sắc vẻ đẹp của
              văn hóa Lô Lô, để sống chậm lại và hòa mình vào thiên nhiên.
            </p>
          </div>
        </section>
        <section className="features section" id="features">
          <h2
            className="section__title title-left"
            style={{
              fontFamily: "Montserrat",
              fontSize: "24px",
              fontWeight: "700",
              lineHeight: "29.26px",
              textAlign: "center",
              color: "#864622",
              marginTop: "-90px",
              marginLeft: "20px",
              marginRight: "20px",
              marginBottom: "48px",
            }}
          >
            VÌ SAO BẠN NÊN CHỌN CAFE CỰC BẮC LÀM ĐIỂM DỪNG CHÂN?
          </h2>
          <div className="features__grid container grid">
            {features1.map(({ id, title, description }, index) => {
              return (
                <div
                  className="features__item-reason"
                  key={index}
                  style={{
                    backgroundColor: "#864622",
                    fontFamily: "Montserrat",
                  }}
                >
                  <h3
                    className="font-bold text-[40px] -mt-4"
                    style={{ fontFamily: "Montserrat" }}
                  >
                    0{id}
                  </h3>
                  <h3
                    className="text-[20px] md:text-[24px] font-bold"
                    style={{ fontFamily: "Montserrat" }}
                  >
                    {title}
                  </h3>
                  <p className="mt-[23px]" style={{ fontFamily: "Montserrat" }}>
                    {description}
                  </p>
                </div>
              );
            })}
          </div>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <iframe
              src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2Fcafesangvoivtv3%2Fvideos%2F1056542348934716%2F&show_text=false&width=476&t=0"
              // width="892"
              // height="409"
              width="500"
              height="500"
              style={{ border: "none", overflow: "hidden" }}
              scrolling="no"
              frameBorder="0"
              allowFullScreen={true}
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
            ></iframe>
            {/* <iframe
                            src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2Fcafesangvoivtv3%2Fvideos%2F1056542348934716%2F&show_text=false&width=476&t=0"
                            width="476"
                            height="476"
                            style="border:none;overflow:hidden"
                            scrolling="no"
                            frameborder="0"
                            allowfullscreen="true"
                            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                            allowFullScreen="true">
                        </iframe> */}
          </div>
        </section>
      </section>
      <section
        className="about section"
        id="about"
        style={{ marginTop: "-180px" }}
      >
        <h2
          className="section__title title-left"
          style={{
            fontFamily: "Montserrat",
            fontSize: "24px",
            fontWeight: "700",
            lineHeight: "30px",
            textAlign: "center",
            color: "#FFFFFF",
            backgroundColor: "#864622",
            padding: "20px 0",
            margin: "0 auto",
            maxWidth: "100%",
            marginBottom: "58px",
          }}
        >
          CÁC SẢN PHẨM NỔI BẬT
        </h2>
        <div
          className="features__grid container"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="about__img-wrapper-test"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <img src={image1} alt="" className="about__image-test-1" />
                        <img src={image2} alt="" className="about__image-test-1" /> */}
            <div style={{ textAlign: "center" }}>
              <a href="/product">
                <img src={image_7} alt="" className="about__image-test-1" />
              </a>
            </div>
            <div
              style={{ textAlign: "center", marginTop: "0px" }}
              className="ml-0 md:ml-[50px]"
            >
              <a href="/product">
                <img src={image_8} alt="" className="about__image-test-1" />
              </a>{" "}
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-[31px] mb-20">
          <Button
            type="primary"
            size="large"
            onClick={handleBookNow}
            className="bg-[#864622] font-bold text-white"
            style={{ borderRadius: "25px" }}
          >
            ĐẶT HÀNG NGAY
          </Button>
        </div>
      </section>
      <section
        className="about section"
        id="about"
        style={{ marginTop: "-80px" }}
      >
        <h2
          className="section__title title-left"
          style={{
            fontFamily: "Montserrat",
            fontSize: "24px",
            fontWeight: "700",
            lineHeight: "30px",
            textAlign: "center",
            color: "#FFFFFF",
            backgroundColor: "#864622",
            padding: "20px 0",
            margin: "0 auto",
            maxWidth: "100%",
            marginBottom: "58px",
          }}
        >
          CAFE CỰC BẮC VÀ CÁC KÊNH THÔNG TIN ĐẠI CHÚNG
        </h2>
        <div
          className="features__grid container"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            position: "relative",
          }}
        >
          <div
            className="swiper-button-prev-custom"
            style={{ flex: "0 0 auto", marginRight: "10px" }}
          >
            <button
              className="prev-btn"
              style={{
                fontSize: "24px",
                background: "#864622",
                border: "2px solid #FFFFFF",
                cursor: "pointer",
                width: "50px",
                height: "50px",
                borderRadius: "50%",
                display: "flex",
                color: "white",
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: "5px",
              }}
            >
              ←
            </button>
          </div>

          <Swiper
            spaceBetween={30}
            slidesPerView={3}
            navigation={{
              prevEl: ".swiper-button-prev-custom",
              nextEl: ".swiper-button-next-custom",
            }}
            loop={true}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
            modules={[Autoplay, Navigation]}
          >
            {features.map(({ img, title, description, link }, index) => {
              return (
                <SwiperSlide key={index}>
                  <Link to={link} target="_blank">
                    <div className="features__item">
                      <img src={img} alt="" className="feature__img" />
                      <h3
                        className="feature__title"
                        style={{ color: "hsl(27, 34%, 59%)" }}
                      >
                        {title}
                      </h3>
                      <p
                        className="feature__description"
                        style={{ color: "#864622" }}
                      >
                        {description}
                      </p>
                    </div>
                  </Link>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <div
            className="swiper-button-next-custom"
            style={{ flex: "0 0 auto", marginLeft: "10px" }}
          >
            <button
              className="prev-btn"
              style={{
                fontSize: "24px",
                background: "#864622",
                border: "2px solid #FFFFFF",
                cursor: "pointer",
                width: "50px",
                height: "50px",
                borderRadius: "50%",
                display: "flex",
                color: "white",
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: "5px",
              }}
            >
              →
            </button>
          </div>
        </div>
      </section>
      <section
        className="about section"
        id="about"
        style={{ marginTop: "-58px", marginBottom: "200px" }}
      >
        <h2
          className="section__title title-left"
          style={{
            fontFamily: "Montserrat",
            fontSize: "24px",
            fontWeight: "700",
            lineHeight: "30px",
            textAlign: "center",
            color: "#FFFFFF",
            backgroundColor: "#864622",
            padding: "20px 0",
            margin: "0 auto",
            maxWidth: "100%",
          }}
        >
          CAFE CỰC BẮC VÀ CÁC ĐỐI TÁC
        </h2>
        <div className="about__grid container" style={{ marginTop: "58px" }}>
          <div
            className="about__img-wrapper-test"
            style={{ display: "flex", justifyContent: "center", gap: "40px" }}
          >
            <img src={ocop} alt="" className="about__image-test-1" />
            <img src={partner} alt="" className="about__image-test-1" />
            <img src={certificate} alt="" className="about__image-test-1" />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
